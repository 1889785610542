import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: '/',
    component: () => import('../layout/index.vue'),
    redirect: '/home',
    children: [{
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index.vue')
      },
      {
        path: 'live',
        name: 'live',
        component: () => import('@/views/solutions/live.vue')
      },
      {
        path: 'commerce',
        name: 'commerce',
        component: () => import('@/views/solutions/commerce.vue')
      },
      {
        path: 'education',
        name: 'education',
        component: () => import('@/views/solutions/education.vue')
      },
      {
        path: 'manage',
        name: 'manage',
        component: () => import('@/views/solutions/manage.vue')
      },
      {
        path: 'solutionGame',
        name: 'solutionGame',
        component: () => import('@/views/solutions/solutionGame.vue')
      },
      {
        path: 'website',
        name: 'website',
        component: () => import('@/views/coreBusiness/website.vue')
      },
      {
        path: 'applet',
        name: 'applet',
        component: () => import('@/views/coreBusiness/applet.vue')
      },
      {
        path: 'game',
        name: 'game',
        component: () => import('@/views/coreBusiness/game.vue')
      },
      {
        path: 'core',
        name: 'core',
        component: () => import('@/views/coreBusiness/core.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/about/index.vue')
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/contact/index.vue')
      },
      {
        path: 'cases',
        name: 'cases',
        component: () => import('@/views/typicalCase/index.vue')
      },
      {
        path: 'SayHi',
        name: 'SayHi',
        component: () => import('@/views/typicalCase/SayHi.vue')
      },
      {
        path: 'mvpchat',
        name: 'mvpchat',
        component: () => import('@/views/typicalCase/mvpchat.vue')
      },
      {
        path: 'study',
        name: 'study',
        component: () => import('@/views/typicalCase/study.vue')
      },
      {
        path: 'decoration',
        name: 'decoration',
        component: () => import('@/views/typicalCase/decoration.vue')
      },
      {
        path: 'chateau',
        name: 'chateau',
        component: () => import('@/views/typicalCase/chateau.vue')
      },
      {
        path: 'cake',
        name: 'cake',
        component: () => import('@/views/typicalCase/cake.vue')
      },
      {
        path: 'PrivacyPolicy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/bottomMain/PrivacyPolicy')
      },
      {
        path: 'UserAgreement',
        name: 'UserAgreement',
        component: () => import('@/views/bottomMain/UserAgreement')
      }
    ]
  },
  {
    name: '/home',
    path: "/home",
    component: () => import("@/views/home/index.vue")
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router